import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Descriptions, Tag, List, Divider } from 'antd';
import i18next from 'i18next';
import { FileSearchOutlined } from '@ant-design/icons';
import useSwr from '@src/hooks/useSwr';
import { hasValueInObj } from '@src/utils/hasValueInObj';

import * as S from './styles';

const AuditPayload = ({ availableRoles, availableGroups, event }) => {
  const { t } = useTranslation(['audit', 'permissions']);
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => setOpen(crrOpen => !crrOpen), []);

  const isGroupInCustomer = useMemo(
    () => ['CREATE_CUSTOMER', 'UPDATE_CUSTOMER'].includes(event?.eventName),
    [event?.eventName],
  );

  const treatedPayloadInJSON = useMemo(() => {
    try {
      const payload = JSON.parse(event?.payload);
      return payload;
    } catch {
      return undefined;
    }
  }, [event?.payload]);

  const handlePayloadDescriptions = useCallback(
    (payloadJson, roles) =>
      Array.isArray(payloadJson)
        ? payloadJson?.map(item => {
            let description;

            const isPermissionsRoles = [
              'CREATE_CUSTOMER',
              'UPDATE_CUSTOMER',
              'UPDATE_GROUP',
            ].includes(event?.eventName);

            if (isPermissionsRoles) {
              const itemRole = roles?.find(role => role.id === item.id);
              const translationPath = `permissions:${itemRole?.category}.permissions.${itemRole?.name}`;
              const hasTranslation = i18next.exists(translationPath);
              description = hasTranslation ? t(translationPath) : '';
            } else {
              description = availableGroups?.find(
                role => role.id === item.id,
              )?.name;
            }
            return {
              id: item?.id,
              description,
            };
          })
        : [],
    [event?.eventName, t, availableGroups],
  );

  const { data: availableCustomerRoles } = useSwr(
    isGroupInCustomer && open && treatedPayloadInJSON
      ? `/customers/${treatedPayloadInJSON?.parentId}/roles/`
      : null,
    {
      customerType: treatedPayloadInJSON?.type,
    },
  );

  const treatedPayload = useMemo(() => {
    if (!treatedPayloadInJSON) {
      return undefined;
    }

    const isStringScapeJson = typeof treatedPayloadInJSON === 'string';
    if (isStringScapeJson) {
      return undefined;
    }

    if (isGroupInCustomer) {
      return {
        ...treatedPayloadInJSON,
        roles: handlePayloadDescriptions(
          treatedPayloadInJSON?.roles,
          availableCustomerRoles,
        ),
      };
    }

    const isUpdateGroup = event?.eventName === 'UPDATE_GROUP';

    if (isUpdateGroup) {
      const groupRoles = JSON.parse(treatedPayloadInJSON?.roles);
      return {
        name: treatedPayloadInJSON?.groupName,
        roles: handlePayloadDescriptions(groupRoles, availableRoles),
      };
    }

    return treatedPayloadInJSON;
  }, [
    availableCustomerRoles,
    availableRoles,
    event?.eventName,
    handlePayloadDescriptions,
    isGroupInCustomer,
    treatedPayloadInJSON,
  ]);

  const renderDescriptions = useCallback(
    (items, column) => (
      <Descriptions column={column ?? 2}>
        {Object.entries(items)?.map(
          ([item, value]) =>
            value && (
              <Descriptions.Item key={item} label={t(`payload.${item}`)}>
                {value}
              </Descriptions.Item>
            ),
        )}
      </Descriptions>
    ),
    [t],
  );

  const renderCustomer = useCallback(() => {
    const isCreateCustomer = event?.eventName === 'CREATE_CUSTOMER';

    const treatedApns = treatedPayload?.apns
      ?.map(apn => apn?.nameApn)
      ?.join(', ');

    const treatedLocale = treatedPayload?.user?.attributes?.locale
      ?.split('-')?.[0]
      ?.toLowerCase();

    const treatedRoles = treatedPayload?.roles
      ?.map(item => item.description)
      ?.filter(item => item);
    treatedRoles?.sort();

    const renderDivider = label => (
      <Divider
        orientation="left"
        style={{ textAlign: 'left', opacity: 0.6, fontSize: '1rem' }}
      >
        {t(`payload.${label}`)}
      </Divider>
    );

    const billingOptions = [
      { value: 0, label: t('payload.billing.thirtyDays') },
      { value: 1, label: t('payload.billing.sixtyDays') },
      { value: 2, label: t('payload.billing.ninetyDays') },
    ];

    let nbMonthsToBillAfter;

    if (
      treatedPayload?.nbMonthsToBillAfter ||
      treatedPayload?.nbMonthsToBillAfter === 0
    ) {
      nbMonthsToBillAfter = billingOptions.find(
        item => item.value === treatedPayload?.nbMonthsToBillAfter,
      )?.label;
    }

    const address = {
      city:
        treatedPayload?.address?.city &&
        `${treatedPayload?.address?.city}${
          treatedPayload?.address?.state
            ? `-${treatedPayload?.address?.state}`
            : ''
        }`,
      streetName:
        treatedPayload?.address?.streetName &&
        `${treatedPayload?.address?.streetName}${
          treatedPayload?.address?.number
            ? `, ${treatedPayload?.address?.number}`
            : ''
        }`,
      neighborhood: treatedPayload?.address?.neighborhood,
      country: treatedPayload?.address?.country,
      complement: treatedPayload?.address?.complement,
    };

    const invoicing = {
      nbMonthsToBillAfter,
      dueDay: treatedPayload?.dueDay,
      ignoreErpSync: treatedPayload?.ignoreErpSync,
    };

    return (
      <>
        {renderDescriptions({
          level: <Tag color="blue">{treatedPayload?.type}</Tag>,
          name: treatedPayload?.name,
          tradingName: treatedPayload?.tradingName,
          nickname: treatedPayload?.nickname,
          cpfCnpj: treatedPayload?.cpfCnpj,
          apns: treatedApns,
        })}
        {renderDivider('contact')}
        {renderDescriptions({
          name: treatedPayload?.contact?.name,
          phoneNumber: treatedPayload?.contact?.phoneNumber,
        })}
        {hasValueInObj(address) && [
          renderDivider('address'),
          renderDescriptions(address),
        ]}
        {hasValueInObj(invoicing) && [
          renderDivider('invoicing'),
          renderDescriptions(
            {
              ...invoicing,
              ignoreErpSync: (
                <Tag color={invoicing?.ignoreErpSync ? 'green' : 'red'}>
                  {t(
                    `payload.${
                      invoicing?.ignoreErpSync ? 'activated' : 'disabled'
                    }`,
                  )}
                </Tag>
              ),
            },
            1,
          ),
        ]}
        {isCreateCustomer && (
          <>
            {renderDivider('user')}
            {renderDescriptions({
              firstName: treatedPayload?.user?.firstName,
              lastName: treatedPayload?.user?.lastName,
              username: treatedPayload?.user?.username,
              cpfCnpj: treatedPayload?.user?.attributes?.cpfCnpj,
              phoneNumber: treatedPayload?.user?.attributes?.phoneNumber,
              locale: t(`payload.${treatedLocale ?? 'pt'}`),
            })}
            {renderDescriptions(
              {
                email: treatedPayload?.user?.email,
              },
              1,
            )}
            {renderDivider('permissions')}
            <div
              style={{
                margin: '0 auto',
                maxHeight: 400,
                overflow: 'auto',
              }}
            >
              <List
                loading={!treatedRoles}
                bordered
                size="small"
                dataSource={treatedRoles}
                renderItem={item => (
                  <List.Item style={{ width: '100%' }}>{item}</List.Item>
                )}
              />
            </div>
          </>
        )}
      </>
    );
  }, [event?.eventName, renderDescriptions, treatedPayload, t]);

  const renderUser = useCallback(() => {
    const { attributes, requiredActions } = treatedPayload;
    const actions = [];
    if (requiredActions?.[0]) {
      actions.push(t(`payload.${requiredActions?.[0]}`));
    }
    if (requiredActions?.[1]) {
      actions.push(t(`payload.${requiredActions?.[1]}`));
    }

    const treatedLocale = attributes?.locale?.split('-')?.[0]?.toLowerCase();

    return renderDescriptions(
      {
        firstName: treatedPayload?.firstName,
        lastName: treatedPayload?.lastName,
        locale: t(`payload.${treatedLocale ?? 'pt'}`),
        email: treatedPayload?.email,
        username: treatedPayload?.username,
        requiredActions: actions?.length > 0 ? actions?.join(', ') : undefined,
      },
      1,
    );
  }, [renderDescriptions, treatedPayload, t]);

  const renderUpdateUserGroup = useCallback(
    () =>
      renderDescriptions(
        {
          username: treatedPayload?.userName,
          profileName: <Tag color="blue">{treatedPayload?.groupName}</Tag>,
        },
        1,
      ),
    [renderDescriptions, treatedPayload],
  );

  const renderCreateGroup = useCallback(
    () =>
      renderDescriptions({
        name: <Tag color="blue">{treatedPayload?.name}</Tag>,
      }),
    [renderDescriptions, treatedPayload],
  );

  const renderUpdateGroup = useCallback(() => {
    const roles = treatedPayload?.roles
      ?.map(item => item.description)
      ?.filter(item => item);

    roles.sort();

    return (
      <div
        style={{
          margin: '0 auto',
          maxHeight: 400,
          overflow: 'auto',
        }}
      >
        {renderDescriptions({
          name: <Tag color="blue">{treatedPayload?.name}</Tag>,
        })}

        <List
          loading={!roles}
          bordered
          size="small"
          dataSource={roles}
          renderItem={item => (
            <List.Item style={{ width: '100%' }}>{item}</List.Item>
          )}
        />
      </div>
    );
  }, [renderDescriptions, treatedPayload?.name, treatedPayload?.roles]);

  const renderConvertCustomerToBroker = useCallback(
    () =>
      renderDescriptions(
        {
          name: treatedPayload?.name,
          nickname: treatedPayload?.nickname,
          cpfCnpj: treatedPayload?.cpfCnpj,
        },
        1,
      ),
    [renderDescriptions, treatedPayload],
  );

  const renderDeleteCustomer = useCallback(
    () =>
      renderDescriptions({
        id: treatedPayload?.id,
        name: treatedPayload?.name,
        nickname: treatedPayload?.nickname,
        cpfCnpj: treatedPayload?.cpfCnpj,
      }),
    [renderDescriptions, treatedPayload],
  );

  const renderDeleteUser = useCallback(
    () =>
      renderDescriptions(
        {
          firstName: treatedPayload?.firstName,
          lastName: treatedPayload?.lastName,
          username: treatedPayload?.username,
          email: treatedPayload?.email,
        },
        1,
      ),
    [renderDescriptions, treatedPayload],
  );

  const renderDeleteGroup = useCallback(
    () =>
      renderDescriptions({
        name: treatedPayload?.name && (
          <Tag color="blue">{treatedPayload?.name}</Tag>
        ),
      }),
    [renderDescriptions, treatedPayload],
  );

  const treatedData = useMemo(() => {
    switch (event?.eventName) {
      case 'CREATE_CUSTOMER':
      case 'UPDATE_CUSTOMER':
        return renderCustomer();
      case 'CREATE_USER':
      case 'UPDATE_USER':
        return renderUser();
      case 'UPDATE_USER_GROUPS':
        return renderUpdateUserGroup();
      case 'CREATE_GROUP':
        return renderCreateGroup();
      case 'UPDATE_GROUP':
        return renderUpdateGroup();
      case 'CONVERT_TO_BROKER':
        return renderConvertCustomerToBroker();
      case 'DELETE_CUSTOMER':
        return renderDeleteCustomer();
      case 'DELETE_USER':
        return renderDeleteUser();
      case 'DELETE_GROUP':
        return renderDeleteGroup();
      default:
        return treatedPayload;
    }
  }, [
    event?.eventName,
    renderCustomer,
    renderUser,
    renderUpdateUserGroup,
    renderCreateGroup,
    renderUpdateGroup,
    renderConvertCustomerToBroker,
    renderDeleteCustomer,
    renderDeleteUser,
    renderDeleteGroup,
    treatedPayload,
  ]);

  const renderModalContainer = useMemo(() => {
    const hasRoles = ['CREATE_CUSTOMER', 'UPDATE_GROUP'].includes(
      event?.eventName,
    );
    return (
      <S.PayloadModalContent onlyDescriptionItems={!hasRoles}>
        {treatedData}
      </S.PayloadModalContent>
    );
  }, [event?.eventName, treatedData]);

  if (!treatedPayload) {
    return <></>;
  }

  return (
    <>
      <Button type="link" icon={<FileSearchOutlined />} onClick={toggleOpen} />
      <S.PayloadModalContainer
        title={`${t('payload.title')} (${t(`event.${event?.eventName}`)})`}
        open={open}
        onCancel={toggleOpen}
        footer={false}
        destroyOnClose
      >
        {renderModalContainer}
      </S.PayloadModalContainer>
    </>
  );
};

export default AuditPayload;

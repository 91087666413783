import styled, { css } from 'styled-components';
import { Modal } from 'antd';

export const PayloadModalContainer = styled(Modal)`
  .ant-tabs {
    margin: 0 !important;
  }
`;

export const PayloadModalContent = styled.div`
  background: #fafafa;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;

  .ant-list {
    background: #fff;
  }

  ${({ onlyDescriptionItems }) =>
    onlyDescriptionItems &&
    css`
      padding-bottom: 0;
    `}
`;

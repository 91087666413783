import React from 'react';
import roles from '@utils/rolesConstants';

import Inventory from '@src/pages/Management/Inventory/Inventory';
import ActionsQueue from '@src/pages/Management/ActionsQueue/ActionsQueue';
import Customers from '@src/pages/Administrative/Customers/index';
import UserAccounts from '@src/pages/Administrative/Users/index';
import UserGroups from '@src/pages/Administrative/Groups/index';
import Reports from '@src/pages/Management/Reports/Reports';
import Maps from '@src/pages/Management/Maps/Maps';
import Transfer from '@src/pages/Management/Transfer/Transfer';
import Requests from '@src/pages/Financial/Requests/Requests';
import DetailedRequest from '@src/pages/Financial/Requests/DetailedRequest/DetailedRequest';
import Invoices from '@src/pages/Financial/Invoices/Invoices';
import DetailedInvoice from '@src/pages/Financial/Invoices/DetailedInvoice/DetailedInvoice';
import Profile from '@src/pages/All/Profile/Profile';
import NotFound from '@src/pages/All/NotFound/NotFound';
import ERPConfig from '@src/pages/Financial/ERPConfig/ERPConfig';
import Operational from '@src/pages/Management/Operational/Operational';
import Contracts from '@src/pages/Financial/Contracts/Contracts';
import DetailedContract from '@src/pages/Financial/Contracts/DetailedContract/DetailedContract';
import InvoiceActions from '@src/pages/Financial/Invoices/InvoiceActions/index';
import DetailedInvoiceActions from '@src/pages/Financial/Invoices/InvoiceActions/DetailedInvoiceActions/index';
import Dashboard from '@src/pages/Management/Dashboard/Dashboard';
import TermsOfUse from '@src/pages/Administrative/TermsOfUse/TermsOfUse';
import Banners from '@src/pages/Administrative/Banners';
import Home from '@src/pages/Management/Home';
import SmsConfigurations from '@src/pages/Sms/SmsConfigurations/index';
import CompanyRegister from '@src/pages/schedules/CompanyRegister/index';
import ClientsSchedules from '@src/pages/schedules/Customers/index';
import Schedules from '@src/pages/schedules/Schedules/index';
import Credits from '@src/pages/schedules/Schedules/Credits/index';
import CustomerAstroDetails from '@src/pages/schedules/Customers/CustomerDetails/index';
import SchedulesDetails from '@src/pages/schedules/Schedules/SchedulesDetails/index';
import SmsSenders from '@src/pages/Sms/SmsSenders/index';
import SmsContracts from '@src/pages/Sms/SmsPricing/SmsContracts';
import SmsRequest from '@src/pages/Sms/SmsRequests';
import SmsHiringAudit from '@src/pages/Sms/SmsPricing/SmsHiringAudit';
import LorawanInventory from '@src/pages/Lorawan/Inventory';
import LorawanInventoryDetails from '@src/pages/Lorawan/Inventory/InventoryDetails/InventoryDetails';
import LorawanActionsQueue from '@src/pages/Lorawan/ActionsQueue';
import LorawanDashboards from '@src/pages/Lorawan/Dashboards';
import LorawanTransfer from '@src/pages/Lorawan/Transfer';
import LorawanContracts from '@src/pages/Lorawan/Contracts';
import LorawanRequests from '@src/pages/Lorawan/Requests';
import LorawanContractsDetails from '@src/pages/Lorawan/Contracts/DetailedContract';
import LorawanRequestsDetails from '@src/pages/Lorawan/Requests/DetailedRequest';
import LorawanReferrals from '@src/pages/Lorawan/Referrals';
import Alerts from '@src/pages/Alerts';
import AlertsEvents from '@src/pages/Alerts/Events';
import Audit from '@src/pages/Administrative/Audit';

const management = [
  {
    element: <Home />,
    path: '/management',
  },
  {
    role: roles.DASHBOARD,
    element: <Dashboard />,
    path: '/management/dashboards',
  },
  {
    role: roles.MANAGEMENT,
    element: <Inventory />,
    path: '/management/inventory',
  },
  {
    role: roles.ACTIONS_QUEUE,
    element: <ActionsQueue />,
    path: '/management/actions-queue',
  },
  {
    role: roles.VIEW_OPERATIONAL,
    element: <Operational />,
    path: '/management/operational',
  },
  {
    role: roles.REQUEST_REPORT,
    element: <Reports />,
    path: '/management/reports',
  },
  {
    role: roles.VIEW_TRANSFERS,
    element: <Transfer />,
    path: '/management/transfer',
  },
  {
    role: roles.LOCATION,
    element: <Maps />,
    path: '/management/maps',
  },
];

const financial = [
  {
    role: roles.VIEW_PLANS,
    element: <Contracts />,
    path: '/financial/contracts',
  },
  {
    element: <DetailedContract />,
    path: '/financial/contracts/:contractId',
  },
  {
    role: roles.VIEW_ACCOUNTS,
    element: <Requests />,
    path: '/financial/requests',
  },
  {
    element: <DetailedRequest />,
    path: '/financial/requests/:requestId',
  },
  {
    role: roles.VIEW_INVOICES,
    element: <Invoices />,
    path: '/financial/invoices',
  },
  {
    element: <InvoiceActions />,
    path: '/financial/invoices/actions',
  },
  {
    element: <DetailedInvoiceActions />,
    path: '/financial/invoices/actions/details',
  },
  {
    element: <DetailedInvoice />,
    path: '/financial/invoices/:invoiceId/customer/:customerId',
  },
  {
    role: roles.VIEW_ERP_CONFIG,
    element: <ERPConfig />,
    path: '/financial/erp-config',
  },
];

const administrative = [
  {
    role: roles.VIEW_CUSTOMERS,
    element: <Customers />,
    path: '/administrative/customers',
  },
  {
    role: roles.VIEW_USERS,
    element: <UserAccounts />,
    path: '/administrative/accounts',
  },
  {
    role: roles.VIEW_USERS,
    element: <UserGroups />,
    path: '/administrative/roles',
  },
  {
    role: roles.VIEW_AUDIT,
    element: <Audit />,
    path: '/administrative/audit',
  },
  {
    role: roles.MANAGE_BANNERS,
    element: <Banners />,
    path: '/administrative/banners',
  },
  {
    role: roles.MANAGE_TERMS_OF_USE,
    element: <TermsOfUse />,
    path: '/administrative/terms-use',
  },
];

const schedules = [
  {
    element: <CompanyRegister />,
    path: '/schedule/company',
  },
  {
    element: <ClientsSchedules />,
    path: '/schedule/customers',
  },
  {
    element: <CustomerAstroDetails />,
    path: '/schedule/customers/:customerId',
  },
  {
    element: <Schedules />,
    path: '/schedule/schedules',
  },
  {
    element: <SchedulesDetails />,
    path: '/schedule/schedules/:id',
  },
  {
    element: <Credits />,
    path: '/schedule/schedules/credits',
  },
];

const sms = [
  {
    role: roles.SEND_SMS,
    element: <SmsRequest />,
    path: '/sms/requests',
  },
  {
    role: roles.SMS_CONTRACT,
    element: <SmsContracts />,
    path: '/sms/contracts-sms',
  },
  {
    role: roles.MANAGE_SMS,
    element: <SmsConfigurations />,
    path: '/sms/configuration',
  },
  {
    role: roles.SMS_SENDERS,
    element: <SmsSenders />,
    path: '/sms/senders',
  },
  {
    role: roles.SMS_HIRING,
    element: <SmsHiringAudit />,
    path: '/sms/hiring',
  },
];

const alerts = [
  {
    role: roles.VIEW_ALERTS,
    element: <Alerts />,
    path: '/alerts/list',
  },
  {
    role: roles.VIEW_ALERTS,
    element: <AlertsEvents />,
    path: '/alerts/events',
  },
];

const lorawan = [
  {
    role: roles.LORAWAN_VIEW_DASHBOARDS,
    element: <LorawanDashboards />,
    path: '/lorawan/dashboards',
  },
  {
    role: roles.LORAWAN_VIEW_DEVICES,
    element: <LorawanInventory />,
    path: '/lorawan/inventory',
  },
  {
    element: <LorawanInventoryDetails />,
    path: '/lorawan/inventory/:devEui',
  },
  {
    role: roles.LORAWAN_VIEW_ACTIONS_QUEUE,
    element: <LorawanActionsQueue />,
    path: '/lorawan/actions-queue',
  },
  {
    role: roles.LORAWAN_VIEW_CONTRACTS,
    element: <LorawanContracts />,
    path: '/lorawan/contracts',
  },
  {
    element: <LorawanContractsDetails />,
    path: '/lorawan/contracts/:contractId',
  },
  {
    role: roles.LORAWAN_VIEW_REQUESTS,
    element: <LorawanRequests />,
    path: '/lorawan/requests',
  },
  {
    element: <LorawanRequestsDetails />,
    path: '/lorawan/requests/:requestId',
  },
  {
    role: roles.LORAWAN_VIEW_TRANSFERS,
    element: <LorawanTransfer />,
    path: '/lorawan/transfer',
  },
  {
    role: roles.LORAWAN_VIEW_REFERRALS,
    element: <LorawanReferrals />,
    path: '/lorawan/referrals',
  },
];

const all = [
  {
    element: <Profile />,
    path: '/profile',
  },
  {
    element: <NotFound />,
    path: '*',
  },
];

export default [
  ...all,
  ...management,
  ...financial,
  ...administrative,
  ...schedules,
  ...sms,
  ...lorawan,
  ...alerts,
];

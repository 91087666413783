import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Table from '@components/Table';
import useSwr from '@src/hooks/useSwr';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Col, Select, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { dateFormat } from '@src/utils/formatters';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import { Label } from '@src/components/ui/index';
import useSearchParams from '@src/hooks/useSearchParams';

import SessionContext from '@src/store/SessionContext/SessionContext';
import AuditPayload from './AuditPayload/index';
import SelectCustomers from '@src/components/SelectCustomers/index';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50'],
  style: { marginRight: 20 },
};

const Audit = () => {
  const { t } = useTranslation(['audit', 'permissions']);
  const {
    customer: { customerLogged },
  } = useContext(SessionContext);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [showFilters, setShowFilters] = useState(false);
  const [entityName, setEntityName] = useState();
  const [eventName, setEventName] = useState();
  const [username, setUsername] = useState();
  const [usernameValue, setUsernameValue] = useState();
  const [hasUpdateGroupEvent, setHasUpdateGroupEvent] = useState(false);
  const [hasUpdateUserGroupEvent, setHasUpdateUserGroupEvent] = useState(false);
  const [entityId, setEntityId] = useState();

  const { data: availableRoles } = useSwr(
    hasUpdateGroupEvent && customerLogged?.id
      ? `/customers/${customerLogged?.id}/roles/`
      : null,
    {
      customerType: customerLogged?.type,
    },
  );

  const { data: availableGroups } = useSwr(
    hasUpdateUserGroupEvent && customerLogged?.id ? '/groups/' : null,
    {
      customerId: customerLogged?.id,
      filter: 'MINE',
    },
  );

  const { data: users } = useSwr(entityName === 'USER' ? '/users/' : null, {
    linesPerPage: 100,
    filter: 'MINE',
  });

  const { data: profiles } = useSwr(
    entityName === 'GROUP' ? '/groups/' : null,
    {
      linesPerPage: 50,
      filter: 'MINE',
    },
  );

  const userOptions = useMemo(
    () =>
      users?.content?.map(({ id, username, email }) => ({
        label: username ?? email,
        value: id,
      })),
    [users],
  );

  const profileOptions = useMemo(
    () =>
      profiles?.content?.map(({ id, name, attributes }) => ({
        label: attributes?.prettyName || name,
        value: id,
      })),
    [profiles],
  );

  const filters = useMemo(
    () => ({
      entityName,
      eventName,
      username,
      entityId,
    }),
    [entityId, entityName, eventName, username],
  );

  const { data } = useSwr('/service-proxy/audit', {
    page: pagination.current ? pagination.current - 1 : 0,
    linesPerPage: pagination.pageSize,
    ...filters,
  });

  useEffect(() => {
    if (data?.content) {
      const hasAtLeastOneUpdateGroup = data?.content?.some(
        item => item.eventName === 'UPDATE_GROUP',
      );
      const hasAtLeastOneUpdateUserGroup = data?.content?.some(
        item => item.eventName === 'UPDATE_USER_GROUPS',
      );
      setHasUpdateGroupEvent(hasAtLeastOneUpdateGroup);
      setHasUpdateUserGroupEvent(hasAtLeastOneUpdateUserGroup);
    }
  }, [data]);

  useEffect(() => {
    setUsernameValue(username);
  }, [username]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      total: data?.totalElements,
    }));
  }, [data?.totalElements]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: DEFAULT_PAGINATION.current,
    }));
  }, [filters]);

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'entityName',
        setState: setEntityName,
        inTheFilters: true,
      },
      {
        name: 'eventName',
        setState: setEventName,
        inTheFilters: true,
      },
      {
        name: 'username',
        setState: setUsername,
        inTheFilters: true,
      },
      {
        name: 'entityId',
        setState: setEntityId,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const columns = useMemo(
    () => [
      {
        title: t('entity.title'),
        dataIndex: 'entityName',
        render: entityName => t(`entity.${entityName}`),
      },
      {
        title: t('event.title'),
        dataIndex: 'eventName',
        render: eventName => t(`event.${eventName}`),
      },
      { title: t('runBy'), dataIndex: 'username' },
      {
        title: t('executedIn'),
        dataIndex: 'eventTimestamp',
        render: dateFormat,
      },
      {
        title: (
          <div>
            {t('payload.title')}
            <Tooltip title={t('payload.help-content')}>
              <Button type="link" icon={<InfoCircleOutlined />} />
            </Tooltip>
          </div>
        ),
        width: 120,
        dataIndex: 'payload',
        align: 'center',
        render: (_, event) => (
          <AuditPayload
            event={event}
            availableRoles={availableRoles}
            availableGroups={availableGroups?.content}
          />
        ),
      },
    ],
    [availableGroups?.content, availableRoles, t],
  );

  const eventsByEntities = useMemo(
    () => ({
      CUSTOMER: [
        'CREATE_CUSTOMER',
        'UPDATE_CUSTOMER',
        'DELETE_CUSTOMER',
        'CONVERT_TO_BROKER',
      ],
      USER: ['CREATE_USER', 'UPDATE_USER', 'DELETE_USER', 'UPDATE_USER_GROUPS'],
      GROUP: ['CREATE_GROUP', 'UPDATE_GROUP', 'DELETE_GROUP'],
    }),
    [],
  );

  const entitiesOptions = useMemo(
    () =>
      Object.keys(eventsByEntities)?.map(entityName => ({
        label: t(`entity.${entityName}`),
        value: entityName,
      })),
    [eventsByEntities, t],
  );

  const eventsOptions = useMemo(
    () =>
      entityName
        ? eventsByEntities[entityName]?.map(eventName => ({
            label: t(`event.${eventName}`),
            value: eventName,
          }))
        : [],
    [eventsByEntities, entityName, t],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('entity.title'),
        item: (
          <Select
            style={{ width: '100%' }}
            allowClear
            value={entityName}
            options={entitiesOptions}
            onChange={value =>
              handleSetSearchParams({
                entityName: value,
                entityId: undefined,
                eventName: undefined,
              })
            }
          />
        ),
      },
      {
        visible: entityName === 'CUSTOMER',
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('entity.filter.customer'),
        item: (
          <SelectCustomers
            value={entityId ? Number(entityId) : undefined}
            onChange={value => handleSetSearchParams({ entityId: value })}
          />
        ),
      },
      {
        visible: entityName === 'USER',
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('entity.filter.user'),
        item: (
          <Select
            style={{ width: '100%' }}
            allowClear
            value={entityId}
            options={userOptions}
            onChange={value => handleSetSearchParams({ entityId: value })}
            showSearch
            filterOption={(input, option) => {
              return String(option?.label).toLowerCase().indexOf(input) >= 0;
            }}
          />
        ),
      },
      {
        visible: entityName === 'GROUP',
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('entity.filter.profile'),
        item: (
          <Select
            style={{ width: '100%' }}
            allowClear
            value={entityId}
            options={profileOptions}
            onChange={value => handleSetSearchParams({ entityId: value })}
            showSearch
            filterOption={(input, option) => {
              return String(option?.label).toLowerCase().indexOf(input) >= 0;
            }}
          />
        ),
      },
      {
        visible: !!entityName,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('event.title'),
        item: (
          <Select
            style={{ width: '100%' }}
            allowClear
            value={eventName}
            options={eventsOptions}
            onChange={value => handleSetSearchParams({ eventName: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('runBy'),
        item: (
          <Search
            onSearch={value => handleSetSearchParams({ username: value })}
            onChange={({ target: { value } }) => setUsernameValue(value)}
            value={usernameValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      entityName,
      entitiesOptions,
      entityId,
      userOptions,
      profileOptions,
      eventName,
      eventsOptions,
      usernameValue,
      handleSetSearchParams,
    ],
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  return (
    <Table
      title={
        <>
          {t('title')}
          <Filters
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
          />
        </>
      }
      tableKey="audit"
      columns={columns}
      data={data?.content}
      loading={!data && data !== ''}
      extraFilters={
        showFilters && (
          <FiltersCard>
            {tableFilters?.map(
              ({ col: { lg, xl, xs }, label, item, visible }) =>
                visible && (
                  <Col key={label} lg={lg} xl={xl} xs={xs}>
                    {label && (
                      <div>
                        <Label color={'#575962'} htmlFor={label}>
                          {label}
                        </Label>
                      </div>
                    )}
                    {item}
                  </Col>
                ),
            )}
          </FiltersCard>
        )
      }
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default Audit;

import styled from 'styled-components';

export const PrettyJSONContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  .ant-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  pre {
    margin: 0;
  }
`;

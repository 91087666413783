import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Input, Button, message, Tooltip, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import apiMiddleware from '@services/apiMiddleware';
import useSwr from '@hooks/useSwr';
import Table from '@components/Table';
import AdminActions from '@components/AdminActions';
import { formatCpfCnpj } from '@utils/formatters';
import { PlusCircleOutlined } from '@ant-design/icons';
import UserViewerModal from './UserViewerModal';
import SessionContext from '@store/SessionContext/SessionContext';
import useSearchParams from '@src/hooks/useSearchParams';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50'],
  style: { marginRight: 20 },
};

const UserAccounts = () => {
  const { keycloak } = useContext(KeycloakContext);
  const { userRoles } = useContext(SessionContext);
  const userAdminLogged = keycloak.idTokenParsed?.sub;

  const isCustomerCanManage = userRoles?.portal?.indexOf('manage-users') !== -1;

  const { t } = useTranslation('accounts');
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [modalVisible, setModalVisible] = useState(false);
  const [userId, setUserId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');

  const { data: usersData, mutate: mutateUsers } = useSwr('/users/', {
    search,
    page: pagination.current ? pagination.current - 1 : 0,
    linesPerPage: pagination.pageSize,
    filter: 'MINE',
  });

  const { data: groupsData } = useSwr('/groups/');

  // user logged removed
  const users = useMemo(
    () => ({
      ...usersData,
      totalElements: usersData?.totalElements - 1,
      content: usersData?.content?.filter(user => user.id !== userAdminLogged),
    }),
    [usersData, userAdminLogged],
  );

  const deleteUser = useCallback(
    id => apiMiddleware.delete(`/users/${id}`),
    [],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'search',
        setState: setSearch,
      },
    ],
    [],
  );

  const { handleSetSearchParams } = useSearchParams(paramsAttributes);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [search]);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      total: users?.totalElements,
    }));
  }, [users]);

  const onSearch = useCallback(
    value => {
      handleSetSearchParams({ search: value });
      setPagination(oldPagination => ({
        ...oldPagination,
        current: DEFAULT_PAGINATION.current,
      }));
    },
    [handleSetSearchParams],
  );

  const handleTableChange = paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  };

  const handleMutateUsers = useCallback(
    async (type, data) => {
      if (users) {
        if (type === 'CREATE') {
          mutateUsers({
            ...users,
            content: [...users.content, data],
          });
        } else if (type === 'UPDATE') {
          mutateUsers({
            ...users,
            content: users.content?.map(user =>
              user.id === data.id ? data : user,
            ),
          });
        } else {
          mutateUsers({
            ...users,
            content: users.content?.filter(user => user.id !== data.id) || [],
          });
        }
      }
    },
    [users, mutateUsers],
  );

  const handleActions = useCallback(
    async (actionType, userId) => {
      const isUpdateUser = actionType === 'UPDATE';

      if (isUpdateUser) {
        setUserId(userId);
        setModalVisible(true);
      } else {
        try {
          await deleteUser(userId);
          handleMutateUsers('DELETE', {
            id: userId,
          });
          message.success(t('msgs.success-deleting-user'));
        } catch {
          message.error(t('msgs.error-deleting-user'));
        }
      }
    },
    [deleteUser, t, handleMutateUsers],
  );

  const verifyGroupName = useCallback(
    value => (value ? ` - ${value}` : ''),
    [],
  );

  const groupTags = useCallback(
    value => (
      <div
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          wordBreak: 'keep-all',
        }}
      >
        {value?.map(tag => (
          <Tag key={tag.id + Date.now()} color="blue">
            {`${tag.attributes?.prettyName ?? tag.prettyName}`}
            {`${
              verifyGroupName(tag.attributes?.customerName) ??
              verifyGroupName(tag.customerName)
            }`}
          </Tag>
        ))}
      </div>
    ),
    [verifyGroupName],
  );

  const formatGroup = useCallback(
    subGroup => ({
      text: subGroup.attributes.prettyName,
      value: subGroup.attributes.prettyName,
    }),
    [],
  );

  const groupsFilters = useMemo(() => {
    const finalGroups = [];

    groupsData?.content.forEach(group => {
      const isSubgroupAlreadyPush = nameToCheck =>
        finalGroups.some(groupToCompare => groupToCompare.text === nameToCheck);
      group.subGroups?.forEach(
        subGroup =>
          !isSubgroupAlreadyPush(subGroup.attributes.prettyName) &&
          finalGroups.push(formatGroup(subGroup)),
      );
    });

    return finalGroups;
  }, [groupsData, formatGroup]);

  const columnsDefault = useMemo(
    () => [
      { title: t('table.username'), dataIndex: 'username' },
      {
        title: t('table.roles'),
        dataIndex: 'groups',
        render: groupTags,
        filters: groupsFilters,
        onFilter: (value, record) =>
          record.groups.some(group => group.attributes.prettyName === value),
      },
      { title: t('table.email'), dataIndex: 'email' },
      { title: t('table.firstName'), dataIndex: 'firstName' },
      { title: t('table.lastName'), dataIndex: 'lastName' },
      {
        title: t('table.cpfCnpj'),
        dataIndex: '',
        render: value => formatCpfCnpj(value?.attributes.cpfCnpj),
      },
    ],
    [t, groupTags, groupsFilters],
  );

  const columns = useMemo(() => {
    if (isCustomerCanManage) {
      columnsDefault.push({
        title: '',
        dataIndex: 'actions',
        render: (value, record) => (
          <AdminActions
            alertDelete={t('modal.delete.alert')}
            entityToExclude={record.firstName}
            ruleToNotExclude={record.id === userAdminLogged}
            onEdit={() => handleActions('UPDATE', record.id)}
            onDelete={() => handleActions('DELETE', record.id)}
          />
        ),
      });
    }

    return columnsDefault;
  }, [t, handleActions, isCustomerCanManage, userAdminLogged, columnsDefault]);

  const onClose = useCallback(() => {
    setModalVisible(false);
    setUserId('');
  }, []);

  return (
    <>
      {modalVisible && (
        <UserViewerModal
          userId={userId}
          visible={modalVisible}
          onClose={onClose}
          mutateUsers={handleMutateUsers}
        />
      )}
      <Table
        extraActions={
          <>
            <Search
              placeholder={t('search')}
              onSearch={onSearch}
              value={searchValue}
              onChange={({ target: { value } }) => setSearchValue(value)}
              style={{ width: 200, margin: '0 10px' }}
              allowClear
            />
            {isCustomerCanManage && (
              <Tooltip title={t('actions.newAccount')}>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  size="large"
                  onClick={() => setModalVisible(true)}
                />
              </Tooltip>
            )}
          </>
        }
        columns={columns}
        allColumns={columnsDefault}
        data={users?.content || []}
        loading={!users?.content}
        title={t('title')}
        tableKey="userAccounts"
        onChange={handleTableChange}
        pagination={pagination}
      />
    </>
  );
};

export default UserAccounts;
